import React from "react";
import { AppBar, Toolbar, Typography, Button } from "@mui/material";

const NavBar = () => {
	return (
		<AppBar position="static">
			<Toolbar>
				<Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
					Gosell Gardens
				</Typography>
				<Button
					color="inherit"
					onClick={() => {
						window.location.href = "/";
					}}
				>
					Home
				</Button>
				<Button
					color="inherit"
					onClick={() => {
						window.location.href = "/in-stock";
					}}
				>
					In Stock
				</Button>
				<Button
					color="inherit"
					onClick={() => {
						window.location.href = "/coming-soon";
					}}
				>
					Coming Soon
				</Button>
			</Toolbar>
		</AppBar>
	);
};

export default NavBar;
