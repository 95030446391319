import React from "react";
import {
	Box,
	Card,
	CardContent,
	CardMedia,
	Grid2,
	Popover,
	Typography,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { Helmet } from "react-helmet";

import amber_horizon from "../../Images/2025InStockPhotos/amber_horizon.jpeg";
import banana_pepper from "../../Images/2025InStockPhotos/banana_pepper.jpeg";
import bright_bold from "../../Images/2025InStockPhotos/bright_bold.jpeg";
import gerbera from "../../Images/2025InStockPhotos/gerbera.jpeg";
import soft_pastel from "../../Images/2025InStockPhotos/soft_pastel.jpeg";
//pink_petunia
import pink_petunia from "../../Images/2025InStockPhotos/pink_petunia.jpeg";
//banana_bowl
import banana_bowl from "../../Images/2025InStockPhotos/banana_bowl.jpeg";
//sunset_glow
import sunset_glow from "../../Images/2025InStockPhotos/sunset_glow.jpeg";
//twilight_ember
import twilight_ember from "../../Images/2025InStockPhotos/twilight_ember.jpeg";
//wild_meadow
import wild_meadow from "../../Images/2025InStockPhotos/wild_meadow.jpeg";
//fire_ice
import fire_ice from "../../Images/2025InStockPhotos/fire_ice.jpeg";
//golden_sunrise
import golden_sunrise from "../../Images/2025InStockPhotos/golden_sunrise.jpeg";
//begonia_mix
import begonia_mix from "../../Images/2025InStockPhotos/begonia_mix.jpeg";
//metro_picotee
import metro_picotee from "../../Images/2025InStockPhotos/metro_picotee.jpeg";
//marigold
import marigold from "../../Images/2025InStockPhotos/marigold.jpeg";
//hibiscus_mohagany
import hibiscus_mohagany from "../../Images/2025InStockPhotos/hibiscus_mohagany.jpeg";

const InStock = () => {
	const [selectedItem, setSelectedItem] = React.useState(null);

	const handleItemClick = (item) => {
		setSelectedItem(item);
	};

	const handleClosePopover = () => {
		setSelectedItem(null);
	};

	const inStock = [
		{
			name: "Banana Pepper",
			type: "Vegetable",
			potSize: "4 inch pot",
			description: "Hungarian Sweet",
			image: banana_pepper,
		},
		{
			name: "Banana Bowl",
			type: "Combo Bowl",
			potSize: "10 inch bowl",
			description: "Banana Pepper and assorted flowers",
			image: banana_bowl,
		},
		{
			name: "Basil",
			type: "Herb",
			potSize: "4 inch pot",
			description: "Sweet Thai",
		},
		{
			name: "Petunia",
			type: "Flower",
			potSize: "4 Pack",
			description: "Dreams Pink",
			image: pink_petunia,
		},
		{
			name: "Petunia",
			type: "Flower",
			potSize: "4 Pack",
			description: "Dreams Mix Metro Picotee",
			image: metro_picotee,
		},
		//geraniums
		{
			name: "Geranium",
			type: "Flower",
			potSize: "4 inch pot",
			description: "Pinto Premium White/Rose",
		},
		{
			name: "Geranium",
			type: "Flower",
			potSize: "4 inch pot",
			description: "Deep Red",
		},
		//Gerbera
		{
			name: "Gerbera",
			type: "Flower",
			potSize: "1 Gallon Pot",
			description: "Bengal Orange",
			image: gerbera,
		},
		{
			name: "Begonia",
			type: "Flower",
			potSize: "4 Pack",
			description: "Mix Ambassador Mix",
			image: begonia_mix,
		},
		{
			//Sunset Glow Hanging Basket
			name: "Sunset Glow",
			type: "Hanging Basket",
			potSize: "10.5 inch basket with hanger",
			description:
				"Warm, fiery tones with a mix of upright and trailing blooms",
			image: sunset_glow,
		},
		//Soft Pastel Hanging Basket
		{
			name: "Soft Pastel",
			type: "Hanging Basket",
			potSize: "10.5 inch basket with hanger",
			description: "Gentle tones with soft purples, yellows, and whites",
			image: soft_pastel,
		},
		//Bold and Bright
		{
			name: "Bold and Bright",
			type: "Hanging Basket",
			potSize: "10.5 inch basket with hanger",
			description: "Vibrant red, pink, and orange for eye-catching appeal",
			image: bright_bold,
		},
		//Fire and Ice
		{
			name: "Fire and Ice",
			type: "Hanging Basket",
			potSize: "10.5 inch basket with hanger",
			description: "A dramatic contrast of deep red, bright yellow, and white",
			image: fire_ice,
		},
		//Golden Sunrise
		{
			name: "Golden Sunrise",
			type: "Hanging Basket",
			potSize: "10.5 inch basket with hanger",
			description: "Warm tones of orange and yellow for a bright, cheerful mix",
			image: golden_sunrise,
		},
		//Wild Meadow
		{
			name: "Wild Meadow",
			type: "Hanging Basket",
			potSize: "10.5 inch basket with hanger",
			description: "A natural-looking combination with a cottage garden feel",
			image: wild_meadow,
		},
		//Amber Horizon
		{
			name: "Amber Horizon",
			type: "Hanging Basket",
			potSize: "10.5 inch basket with hanger",
			description: "Warm, golden hues with bold contrast",
			image: amber_horizon,
		},
		//twilight Ember
		{
			name: "Twilight Ember",
			type: "Hanging Basket",
			potSize: "10.5 inch basket with hanger",
			description: "Fiery mix of pinks, oranges, and golden tones",
			image: twilight_ember,
		},
		//Marigold
		{
			name: "Marigold Queen Sophia",
			type: "Flower",
			potSize: "4 inch pot",
			description: "Orangish-Red with gold tones",
			image: marigold,
		},
		//Marigold Orange
		{
			name: "Marigold Giant Orange",
			type: "Flower",
			potSize: "4 inch pot",
			description: "Giant Orange",
			image: marigold,
		},
		//Marigold Nosento Limegreen
		{
			name: "Marigold Nosento Limegreen",
			type: "Flower",
			potSize: "4 inch pot",
			description: "Limegreen",
			image: marigold,
		},
		//Hibiscus
		{
			name: "Hibiscus",
			type: "Flower",
			potSize: "4 inch pot",
			description: "Mahogany Splendor",
			image: hibiscus_mohagany,
		},
	];

	//inStockAlphabetical
	inStock.sort((a, b) => (a.name > b.name ? 1 : -1));

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				alignItems: "center",
				width: "100%",
				padding: "2px",
			}}
		>
			<Helmet>
				<meta
					name="description"
					content="Current in stock items at Gosell Gardens in Inver Grove Heights, MN."
				/>
				<meta name="title" content="Gosell Gardens"></meta>

				<meta property="og:title" content="Gosell Gardens" />
				<meta
					property="og:description"
					content="Current in stock items at Gosell Gardens in Inver Grove Heights, MN."
				/>
				<meta property="og:url" content="https://gosellgardens.com" />
				<meta property="og:type" content="website" />
				<title>Gosell Gardens</title>
			</Helmet>
			<Typography variant="h6" sx={{ mb: 2 }}>
				In Stock:
			</Typography>
			<Typography variant="body2" sx={{ mb: 2 }}>
				Here's a list of plants we have prepped and will have available for sale
				on opening day. Please note that availability is subject to change. We
				will update this list as we get closer to day 1!
			</Typography>
			<Typography variant="body2" sx={{ mb: 2 }}>
				Last updated: 4/1/2025
			</Typography>
			<Box sx={{ width: "100%" }}>
				<Grid2 container spacing={2}>
					{inStock.map((item) => (
						<Grid2 size={{ xs: 12, md: 6 }} key={item.description}>
							<Card
								//make  color of card white
								sx={{
									backgroundColor: "#FFFFFF",
									border: "1px solid #000000",
									borderRadius: "5px",
									marginBottom: "10px",
									//on hover, change the border color and pointer
									"&:hover": {
										border: "1px solid #FFC0CB",
										backgroundColor: "gray",
										cursor: "pointer",
									},
								}}
								key={item.id}
								onClick={() => handleItemClick(item)}
							>
								{item.image && (
									<CardMedia
										component="img"
										height="140"
										//center the image
										sx={{ objectFit: "contain" }}
										image={item.image}
										alt={item.name}
									/>
								)}
								<CardContent>
									<Typography variant="h6" component="div">
										{item.name}
									</Typography>
									<Typography variant="body2" color="text.secondary">
										{item.description}
									</Typography>
								</CardContent>
							</Card>
						</Grid2>
					))}
				</Grid2>
				<Popover
					open={selectedItem !== null}
					anchorEl={selectedItem}
					onClose={handleClosePopover}
					anchorOrigin={{
						vertical: "center",
						horizontal: "center",
					}}
					transformOrigin={{
						vertical: "center",
						horizontal: "center",
					}}
				>
					{
						//add an x to close the popover in the top right corner
					}
					<Box sx={{ display: "flex", justifyContent: "flex-end" }}>
						<Close onClick={handleClosePopover} />
					</Box>

					{selectedItem && (
						<Box sx={{ p: 2 }}>
							<Grid2 container spacing={2}>
								<Grid2 size={{ xs: 12, md: 6 }}>
									{selectedItem.image ? (
										<img
											src={selectedItem.image}
											alt={selectedItem.name}
											width={"75%"}
										/>
									) : (
										<Typography variant="body2" color="text.secondary">
											Image coming soon. The plant is still growing!
										</Typography>
									)}
								</Grid2>
								<Grid2 size={{ xs: 12, md: 6 }}>
									<Typography variant="h6" component="div">
										{selectedItem.name}
									</Typography>
									<Typography variant="body2" color="text.secondary">
										{selectedItem.description}
									</Typography>
									<Typography variant="body2" color="text.secondary">
										{selectedItem.potSize}
									</Typography>
								</Grid2>
							</Grid2>
						</Box>
					)}
				</Popover>
			</Box>
		</Box>
	);
};

export default InStock;
