import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import "./index.css";
import Home from "./Pages/Home";
import InStock from "./Pages/InStock";
import ComingSoon from "./Pages/ComingSoon";
import NavBar from "./Components/NavBar";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<React.StrictMode>
		<Box
			padding={2}
			backgroundColor={
				//light red
				"#FFC0CB"
			}
		>
			<Typography variant="h4" align="center">
				Opening ~ May 1st 2025
			</Typography>
		</Box>
		<NavBar />
		<BrowserRouter>
			<Routes>
				<Route path="/" element={<Home />} />
				<Route path="/in-stock" element={<InStock />} />
				<Route path="/coming-soon" element={<ComingSoon />} />
			</Routes>
		</BrowserRouter>
	</React.StrictMode>
);
