import React from "react";
import {
	Box,
	ToggleButton,
	ToggleButtonGroup,
	Typography,
} from "@mui/material";
import DropdownList from "../../Components/DropdownList";
import { Helmet } from "react-helmet";

const ComingSoon = () => {
	const [selectedTab, setSelectedTab] = React.useState("flowers");

	const handleTabChange = (event, newTab) => {
		setSelectedTab(newTab);
	};

	const flowers = [
		//allysum
		{ name: "Alyssum", type: "Flower", description: "Wonderland White" },
		{ name: "Alyssum", type: "Flower", description: "E Bonnet Violet" },
		{
			name: "Alyssum",
			type: "Flower",
			description: "Raspberry Stream",
		},
		{ name: "Amaranthus", type: "Flower", description: "Red Spike" },
		{ name: "Artemisia", type: "Flower", description: "Sweet Annie Organic" },
		{ name: "Asclepias", type: "Flower", description: "Apollo Orange" },
		{ name: "Aster", type: "Flower", description: "Tower White" },
		{ name: "Aster", type: "Flower", description: "Lady Coral Lavender" },
		{ name: "Aster", type: "Flower", description: "Bonita Shell Pink Film" },
		{ name: "Begonia", type: "Flower", description: "Hula Red" },
		{ name: "Begonia", type: "Flower", description: "Ambassador Pink" },
		{ name: "Begonia", type: "Flower", description: "Ambassador Scarlet" },
		{ name: "Begonia", type: "Flower", description: "Ambassador White" },
		{
			name: "Calibrachoa",
			type: "Flower",
			description: "Caliloco Spicy Honey",
		},
		{ name: "Celosia", type: "Flower", description: "Sunday Bright Pink" },
		{ name: "Celosia", type: "Flower", description: "Sunday Gold" },
		{ name: "Celosia", type: "Flower", description: "Bright Sparks Mix" },
		{ name: "Coleus", type: "Flower", description: "Chocolate Mint" },
		{ name: "Coleus", type: "Flower", description: "Dark Star" },
		{ name: "Cosmos", type: "Flower", description: "Cosmic Orange" },
		{
			name: "Cynoglossum(forget-me-not)",
			type: "Flower",
			description: "Firmament",
		},
		{ name: "Daisy", type: "Flower", description: "Denver Daisy" },
		{ name: "Daisy", type: "Flower", description: "Primadonna Deep Rose" },
		{ name: "Daisy", type: "Flower", description: "Crazy Daisy" },
		{
			name: "Delphinium",
			type: "Flower",
			description: "Magic Fountains Lilac Pink White Bee",
		},
		{ name: "Dianthus", type: "Flower", description: "Amazon Neon Duo" },
		{ name: "Dianthus", type: "Flower", description: "Marie Chabaud" },
		{
			name: "Dianthus",
			type: "Flower",
			description: "Chabaud Orange Sherbert",
		},
		{ name: "Eucalyptus", type: "Flower", description: "Silver Dollar" },
		{ name: "Gazania", type: "Flower", description: "Kiss Orange" },
		{
			name: "Geranium",
			type: "Flower",
			description: "Pinto Premium White/Rose",
		},
		{ name: "Geranium", type: "Flower", description: "Deep Red" },
		{ name: "Geranium", type: "Flower", description: "Maverick Orange" },
		{ name: "Geranium", type: "Flower", description: "Pinto Premium White" },
		{ name: "Geranium", type: "Flower", description: "Pinto Premium Violet" },
		{ name: "Geranium", type: "Flower", description: "Pinto Premium Deep Red" },
		{ name: "Geranium", type: "Flower", description: "Salmon Splash" },
		{ name: "Gerbera", type: "Flower", description: "Bengal Orange Eye" },
		{ name: "Hibiscus", type: "Flower", description: "Mahogany Splendor" },
		{ name: "Impatiens", type: "Flower", description: "New Guinea Lavender" },
		{ name: "Impatiens", type: "Flower", description: "Imara XDR White" },
		{ name: "Impatiens", type: "Flower", description: "Beacon Orange" },
		{ name: "Impatiens", type: "Flower", description: "Beacon Salmon" },
		{ name: "Impatiens", type: "Flower", description: "Beacon White" },
		{ name: "Impatiens", type: "Flower", description: "Violet Shades" },
		{ name: "Lavender", type: "Flower", description: "Munstead" },
		{ name: "Lantana", type: "Flower", description: "Gem Ruby" },
		{
			name: "Leucanthemum",
			type: "Flower",
			description: "Shasta Daisy - Crazy Daisy",
		},
		{ name: "Marigold", type: "Flower", description: "Queen Sophia" },
		{ name: "Marigold", type: "Flower", description: "Nosento Limegreen" },
		{ name: "Marigold", type: "Flower", description: "Giant Orange" },
		{ name: "Marigold", type: "Flower", description: "Strawberry Blonde" },
		{ name: "Pansy", type: "Flower", description: "Colossus Blotch Mix" },
		{ name: "Pansy", type: "Flower", description: "Cool Wave Raspberry" },
		{ name: "Pansy", type: "Flower", description: "Atlas Black" },
		{ name: "Petunia", type: "Flower", description: "Celebrity Burgundy Star" },
		{ name: "Petunia", type: "Flower", description: "Dreams Midnight" },
		{ name: "Petunia", type: "Flower", description: "Daddy Blue" },
		{
			name: "Petunia",
			type: "Flower",
			description: "Dreams Mix Metro Picotee",
		},
		{ name: "Petunia", type: "Flower", description: "Dreams Pink" },
		{ name: "Snapdragon", type: "Flower", description: "Avignon Deep Rose" },
		{ name: "Snapdragon", type: "Flower", description: "Potomac Yellow" },
		{ name: "Snapdragon", type: "Flower", description: "Potomac Royal" },
		{ name: "Snapdragon", type: "Flower", description: "Orleans Early Yellow" },
		{ name: "Snapdragon", type: "Flower", description: "Snapshot Coral Bcol" },
		{ name: "Snapdragon", type: "Flower", description: "Snaptastic Yellow" },
		{ name: "Sunflower", type: "Flower", description: "Sunrich Lemon" },
		{ name: "Sunpatiens", type: "Flower", description: "Coral Pink" },
		{ name: "Viola", type: "Flower", description: "Sorbet XP" },
		{ name: "Viola", type: "Flower", description: "Sorbet Tiger Eye" },
		{ name: "Viola", type: "Flower", description: "Angel Tiger Eye" },
		{ name: "Viola", type: "Flower", description: "Penny Beaconsfield" },
		{ name: "Zinnia", type: "Flower", description: "Macarenia" },
		{ name: "Ageratum", type: "Flower", description: "Mix Timeless Mix" },
		{ name: "Begonia", type: "Flower", description: "Mix Ambassador Mix" },
		{ name: "Celosia", type: "Flower", description: "Mix Sunday Mix" },
		{
			name: "Dianthus",
			type: "Flower",
			description: "Mix Chabaud Picotee Double Mix",
		},
		{ name: "Digitalis", type: "Flower", description: "Mix Camelot Mix" },
		{ name: "Viola", type: "Flower", description: "Mix Sorbet Formula Mix" },
		{ name: "Viola", type: "Flower", description: "Mix Penny All Season Mix" },

		{ name: "Pentas", type: "Flower", description: "Graffiti Selected Mix" },
	];
	const fruits = [
		{ name: "Squash", type: "Fruit", description: "ButterBaby" },
		{ name: "Pumpkin", type: "Fruit", description: "New England Pie Organic" },
		{ name: "Honey-do", type: "Melon", description: "Sugar Rush" },
		{ name: "Watermelon", type: "Melon", description: "Crimson Sweet" },
	];

	const veggies = [
		{ name: "Basil", type: "Herb", description: "Sweet Thai" },
		{ name: "Chamomile", type: "Herb", description: "German" },
		{ name: "Cilantro", type: "Herb", description: "Marino Organic" },
		{ name: "Cilantro", type: "Herb", description: "Cruiser" },
		{ name: "Dill", type: "Herb", description: "Bouquet Organic" },
		{ name: "Lemon Grass", type: "Herb", description: "East Indian" },
		{ name: "Oregano", type: "Herb", description: "Vulgare" },
		{ name: "Parsley", type: "Herb", description: "Giant of Italy" },
		{ name: "Rosemary", type: "Herb", description: "Rosemary" },
		{ name: "Thyme", type: "Herb", description: "German" },
		{ name: "Bean", type: "Vegetable", description: "Provider" },
		{ name: "Bean", type: "Vegetable", description: "Jade" },
		{ name: "Broccoli", type: "Vegetable", description: "Belstar Organic" },
		{ name: "Broccoli", type: "Vegetable", description: "Green Magic" },
		{ name: "Cabbage - Green", type: "Vegetable", description: "Boltran" },
		{
			name: "Cabbage - Red",
			type: "Vegetable",
			description: "Ruby Perfection",
		},
		{ name: "Carrot", type: "Vegetable", description: "Narvik" },
		{ name: "Carrot", type: "Vegetable", description: "Mokum" },
		{ name: "Celery", type: "Vegetable", description: "Kelvin" },
		{ name: "Cucumber", type: "Vegetable", description: "Dasher" },
		{ name: "Cucumber", type: "Vegetable", description: "Unagi" },
		{
			name: "Cucumber",
			type: "Vegetable",
			description: "Marketmore 76 organic",
		},
		{
			name: "Cucumber(pickle)",
			type: "Vegetable",
			description: "H-19 Little Leaf Organic",
		},
		{ name: "Lettuce", type: "Vegetable", description: "Sunland" },
		{ name: "Lettuce", type: "Vegetable", description: "Winter Density" },
		{ name: "Onion - Red", type: "Vegetable", description: "Cabernet" },
		{ name: "Onion - Spring", type: "Vegetable", description: "Feast" },
		{
			name: "Onion - Spring",
			type: "Vegetable",
			description: "Parade organic",
		},
		{ name: "Onion - White", type: "Vegetable", description: "White Castle" },
		{ name: "Onion - Yellow", type: "Vegetable", description: "Patterson" },
		{ name: "Pea", type: "Vegetable", description: "Sugar Snap" },
		{ name: "Pepper", type: "Vegetable", description: "Cornito Organic" },
		{ name: "Pepper", type: "Vegetable", description: "Sweet Banana" },
		{ name: "Pepper", type: "Vegetable", description: "Lunchbox" },
		{ name: "Pepper", type: "Vegetable", description: "Jalapeño" },
		{ name: "Pepper - Green Bell", type: "Vegetable", description: "Classic" },
		{ name: "Pepper - Red Bell", type: "Vegetable", description: "Sprinter" },
		{
			name: "Pepper - Red Bell",
			type: "Vegetable",
			description: "King Arthur",
		},
		{
			name: "Pepper - Yellow Bell",
			type: "Vegetable",
			description: "Sweet Sunrise organic",
		},
		{ name: "Sweet Corn", type: "Vegetable", description: "Solstice" },
		{ name: "Tomato", type: "Vegetable", description: "Supersweet" },
		{ name: "Tomato", type: "Vegetable", description: "San Marzano" },
		{ name: "Tomato", type: "Vegetable", description: "Monica" },
	];

	const grasses = [
		{
			name: "Boulder Blue Grass",
			type: "Festuca",
			description: "Boulder Blue Grass",
		},
		{ name: "Karl Forester", type: "Feather Reed", description: "Blue Oat" },
	];

	const groupedFlowers = flowers.reduce((acc, flower) => {
		if (!acc[flower.name]) {
			acc[flower.name] = [];
		}
		acc[flower.name].push(flower);
		return acc;
	}, {});

	// Sort the grouped flowers alphabetically
	const sortedGroupedFlowers = Object.keys(groupedFlowers)
		.sort()
		.reduce((acc, key) => {
			acc[key] = groupedFlowers[key];
			return acc;
		}, {});

	const groupedFruits = fruits.reduce((acc, fruit) => {
		if (!acc[fruit.name]) {
			acc[fruit.name] = [];
		}
		acc[fruit.name].push(fruit);
		return acc;
	}, {});

	const groupedGrasses = grasses.reduce((acc, grass) => {
		if (!acc[grass.name]) {
			acc[grass.name] = [];
		}
		acc[grass.name].push(grass);
		return acc;
	}, {});

	// Sort the grouped fruits alphabetically
	const sortedGroupedFruits = Object.keys(groupedFruits)
		.sort()
		.reduce((acc, key) => {
			acc[key] = groupedFruits[key];
			return acc;
		}, {});

	const groupedVeggies = veggies.reduce((acc, veggie) => {
		if (!acc[veggie.name]) {
			acc[veggie.name] = [];
		}
		acc[veggie.name].push(veggie);
		return acc;
	}, {});

	// Sort the grouped veggies alphabetically
	const sortedGroupedVeggies = Object.keys(groupedVeggies)
		.sort()
		.reduce((acc, key) => {
			acc[key] = groupedVeggies[key];
			return acc;
		}, {});

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				alignItems: "center",
				width: "100%",

				padding: "2px",
			}}
		>
			<Helmet>
				<meta
					name="description"
					content="Coming soon items at Gosell Gardens in Inver Grove Heights, MN."
				/>
				<meta name="title" content="Gosell Gardens"></meta>

				<meta property="og:title" content="Gosell Gardens" />
				<meta
					property="og:description"
					content="Coming soon items at Gosell Gardens in Inver Grove Heights, MN."
				/>
				<meta property="og:url" content="https://gosellgardens.com" />
				<meta property="og:type" content="website" />
				<title>Gosell Gardens</title>
			</Helmet>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
					width: "100%",
					padding: "2px",
				}}
			>
				<Typography variant="h6" sx={{ mb: 2 }}>
					Coming Soon:
				</Typography>
				<Typography variant="body2" sx={{ mb: 2 }}>
					Heres some plants we're working on. Check back soon for availability!
				</Typography>
			</Box>

			<ToggleButtonGroup
				exclusive
				value={selectedTab}
				onChange={handleTabChange}
			>
				<ToggleButton value="flowers">Flowers</ToggleButton>
				<ToggleButton value="fruits">Fruits</ToggleButton>
				<ToggleButton value="veggies">Veggies</ToggleButton>
				<ToggleButton value="grasses">Grasses</ToggleButton>
				<ToggleButton value="eggs">Eggs</ToggleButton>
			</ToggleButtonGroup>
			{selectedTab === "flowers" && (
				<DropdownList title="Flowers" items={sortedGroupedFlowers} />
			)}
			{selectedTab === "fruits" && (
				<DropdownList title="Fruits" items={sortedGroupedFruits} />
			)}
			{selectedTab === "veggies" && (
				<DropdownList title="Veggies" items={sortedGroupedVeggies} />
			)}
			{selectedTab === "grasses" && (
				<DropdownList title="Grasses" items={groupedGrasses} />
			)}
			{selectedTab === "eggs" && (
				<Box sx={{ width: "100%", p: 2 }}>
					<Typography variant="body2" sx={{ mb: 2 }}>
						Chicken eggs are coming soon! Estimated egg availability June/July
						2025. Check back soon!
					</Typography>

					<Typography variant="body2" sx={{ mb: 2 }}>
						Duck eggs are coming soon! We have a small flock of ducks that are
						currently growing. Check back soon for availability!
					</Typography>
				</Box>
			)}
		</Box>
	);
};

export default ComingSoon;
