import React from "react";
import { Box, Grid2, Typography } from "@mui/material";

import { Helmet } from "react-helmet";

const Home = () => {
	return (
		<Box>
			<Helmet>
				<meta
					name="description"
					content="Gosell Gardens is a family-owned farm / backyard nursery located in Inver Grove Heights, MN. We offer a wide variety of annuals, perrenials, trees, shrubs and produce for sale. Our farm is open to the public during the growing season."
				/>
				<meta name="title" content="Gosell Gardens"></meta>

				<meta property="og:title" content="Gosell Gardens" />
				<meta
					property="og:description"
					content="Gosell Gardens is a family-owned farm / backyard nursery located in Inver Grove Heights, MN. We offer a wide variety of annuals, perrenials, trees, shrubs and produce for sale. Our farm is open to the public during the growing season."
				/>
				<meta property="og:url" content="https://gosellgardens.com" />
				<meta property="og:type" content="website" />
				<title>Gosell Gardens</title>

				<link rel="canonical" href="https://gosellgardens.com" />
			</Helmet>

			<Box padding={2}>
				<Typography
					variant="h1"
					align="center"
					sx={{
						color: "primary",
						fontWeight: "bold",
						fontSize: "2.5rem",
						mt: 2,
						mb: 2,
					}}
				>
					Welcome to Gosell Gardens: Your friendly neighborhood farm / backyard
					nursery!
				</Typography>
				<Typography variant="body1" align="center">
					We are a family-owned farm located in Inver Grove Heights, MN. We
					offer a wide variety of annuals, perrenials, trees, shrubs and produce
					for sale. Our farm is open to the public during the growing season. We
					look forward to seeing you soon!
				</Typography>
			</Box>
			<Box padding={2}>
				<Grid2 container spacing={2} sx={{ mt: 4 }}>
					<Grid2 size={{ xs: 12, md: 6 }}>
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								justifyContent: "left",
								alignItems: "left",
								padding: "2px",
							}}
						>
							{/* Google Map */}
							{/* Replace the iframe src with your own Google Map embed code */}
							<iframe
								title="Farm Location"
								src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2830.2489868642356!2d-93.0336870872974!3d44.81649196632605!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x87f7d3840216f02f%3A0x58efadaf4ed7a063!2sGosell%20Gardens!5e0!3m2!1sen!2sus!4v1742910248308!5m2!1sen!2sus"
								width="100%"
								height="400"
								style={{ border: 0 }}
								allowFullScreen=""
								loading="lazy"
							></iframe>
							<Box>
								<Typography variant="h6" sx={{ mb: 2 }}>
									Contact Us:
								</Typography>
								<Typography variant="body1" sx={{ mb: 2 }}>
									Phone: 651-239-4444
								</Typography>
								<Typography variant="body1" sx={{ mb: 2 }}>
									Email: connor@gosellgardens.com (Connor - Owner, Plants)
								</Typography>
								<Typography variant="body1" sx={{ mb: 2 }}>
									Email: emily@gosellgardens.com (Emily - Owner, Animals)
								</Typography>
								<Typography variant="body1" sx={{ mb: 2 }}>
									In season hours typically Wednesday to Saturday 7am - 2pm,
									Sunday at the Inver Grove Heights Farmers Market and by
									appointment. We also have a 24/7 produce stand on site later
									in the season as we harvest.
								</Typography>
							</Box>
						</Box>
					</Grid2>
				</Grid2>
			</Box>
		</Box>
	);
};

export default Home;
